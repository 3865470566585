var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { justify: "center", value: _vm.value, persistent: "" } },
    [
      _c(
        "v-container",
        { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-card",
            { attrs: { color: "fill" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "11" } },
                    [
                      _c("v-card-title", [
                        _vm._v(
                          "Promotions on Batch: " +
                            _vm._s(_vm.selectedBatch.name)
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-2",
                          attrs: { text: "", icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("update:value", false)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card",
                { attrs: { "ma-0": "", "pa-0": "", fluid: "" } },
                [
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.headers,
                      height: _vm.tableHeight,
                      items: _vm.promos,
                      "items-per-page": _vm.pageSize,
                      "no-data-text": "No Data",
                      "loading-text": "Loading...",
                      loading: _vm.loading,
                      dense: "",
                      "fixed-header": "",
                      "hide-default-footer": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.enforce_compliance",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              item.enforce_compliance
                                ? _c(
                                    "span",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "green" } },
                                        [
                                          _vm._v(
                                            "mdi-checkbox-marked-circle-outline"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "span",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "red" } },
                                        [_vm._v("mdi-close-circle-outline")]
                                      ),
                                    ],
                                    1
                                  ),
                            ]
                          },
                        },
                        {
                          key: "item.promo_number",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              item.promo_number
                                ? _c("span", [
                                    _vm._v(" " + _vm._s(item.promo_number)),
                                  ])
                                : _c("span", [_vm._v(" N/A ")]),
                            ]
                          },
                        },
                        {
                          key: "item.info",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.copyID(item)
                                                    },
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-information"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v(_vm._s(item.promo_id))])]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
              _c(
                "v-toolbar",
                { attrs: { dense: "", flat: "", clolor: "#fafafa" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center", justify: "end" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticStyle: { "max-width": "150px" },
                          attrs: { cols: "2" },
                        },
                        [
                          _c("v-select", {
                            staticClass: "mt-8",
                            attrs: {
                              dense: "",
                              items: _vm.pageSizes,
                              label: "Items Per Page",
                              select: "",
                              "menu-props": {
                                top: true,
                                offsetY: true,
                                maxHeight: 500,
                              },
                            },
                            model: {
                              value: _vm.pageSize,
                              callback: function ($$v) {
                                _vm.pageSize = $$v
                              },
                              expression: "pageSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-2",
                          attrs: {
                            icon: "",
                            large: "",
                            disabled: _vm.disablePrevious,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.previous()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                        1
                      ),
                      _c("small", [_vm._v("Page " + _vm._s(_vm.page))]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-2",
                          attrs: {
                            icon: "",
                            large: "",
                            disabled: _vm.disableNext,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.next()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }